import { useQuery } from 'react-query';

import apiClient from '../../../services/api';

export const getBuckets = async (): Promise<any> => {
    const response = await apiClient.get<any>(`/api/buckets/`);

    return [{ name: 'All', id: 'all', code: 'all' }, ...response.data.data];
};

export function useBuckets() {
    return useQuery(['use-buckets'], () => getBuckets(), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });
}
