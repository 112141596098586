import { useQuery } from 'react-query';

import apiClient from '../../../services/api';

export const getCurrencies = async (): Promise<any> => {
    const response = await apiClient.get<any>(`/api/currencies/`);

    return response.data.data;
};

export function useCurrencies() {
    return useQuery(['use-currencies'], () => getCurrencies(), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });
}
