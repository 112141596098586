import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { List } from '@mui/material';
import TicketIcon from '@mui/icons-material/AirplaneTicketOutlined';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useEffect } from 'react';

import { useBuckets } from '../../pages/Activities/api/useBuckets';
import { Bucket, useCurrentBucket } from '../../context/CurrentBucketContext';

const useStyles = makeStyles({
    selected: {
        backgroundColor: '#001261 !important',
    },
    listItem: {
        overflow: 'visible',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
    },
});

const NavigationList = () => {
    const buckets = useBuckets();
    const classes = useStyles();
    const location = useLocation();
    const params = useParams();
    const { setCurrentBucket } = useCurrentBucket();

    const isBucketActive = (bucketId: string) => {
        const paths = [
            `/activities/${bucketId}/all`,
            `/activities/${bucketId}/to-book`,
            `/activities/${bucketId}/requested`,
            `/activities/${bucketId}/to-cancel`,
            `/activities/${bucketId}/processed`,
        ];

        return paths.includes(location.pathname);
    };

    useEffect(() => {
        const foundBucket = buckets?.data?.find((b: Bucket) => b.id === parseInt(params.bucketID || ''));

        if (foundBucket) setCurrentBucket(foundBucket);
    }, [params.id, buckets]);

    return (
        <List role="menu">
            {buckets?.data?.map((bucket: any) => (
                <ListItem
                    sx={{ color: 'white' }}
                    key={bucket.id}
                    to={`/activities/${bucket.id}/all`}
                    component={NavLink}
                    onClick={() => setCurrentBucket(bucket)}
                    className={clsx(isBucketActive(bucket.id) ? classes.selected : '', classes.listItem)}
                >
                    <ListItemIcon>
                        <TicketIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{bucket.name}</ListItemText>
                </ListItem>
            ))}
        </List>
    );
};

export default NavigationList;
