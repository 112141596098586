import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useNavigationBarLayoutStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentContainer: {
            flexGrow: 1,
            height: `calc(100% - ${56}px)`,
            marginTop: 56,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: '0',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '3em',
                padding: '1em 1em',
                '&[class*="iframePage"]': { padding: `${theme.spacing(0)} !important` },
            },
            [theme.breakpoints.up('sm')]: {
                marginLeft: '3em',
                padding: '3rem 2rem 6rem',
                '&[class*="iframePage"]': { padding: `${theme.spacing(0)} !important` },
            },
        },
        content: {
            maxWidth: '1350px',
            margin: '0 auto',
            marginLeft: '15%',
        },
        contentShift: {
            [theme.breakpoints.up('sm')]: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                '&[class*="iframePage"]': { padding: `${theme.spacing(0)} !important` },
                marginLeft: '200px',
            },
        },
    }),
);

export default useNavigationBarLayoutStyles;
