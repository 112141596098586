import dayjs from 'dayjs';
import apiClient from '../../../services/api';

const downloadExcel = async (dateRange: any): Promise<unknown> => {
    const startDate = dateRange && dayjs(dateRange[0]).isValid() ? dayjs(dateRange[0]).format('YYYY-MM-DD HH:mm:ss') : '';

    const endDate =
        dateRange && dayjs(dateRange[1]).isValid() ? dayjs(dateRange[1]).clone().endOf('day').format('YYYY-MM-DD HH:mm:ss') : '';

    const response = await apiClient
        .get(`/api/requirements/download/?&startDate=${startDate}&endDate=${endDate}`, {
            responseType: 'blob',
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            console.log(error);
        });
    return response;
};

export default downloadExcel;
