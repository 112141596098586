import { NumberedTab, NumberedTabs, TabPanel } from '@get-e/react-components';
import { Grid } from '@mui/material';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ActivitiesTabs } from './index';
import BookingsPanel from './components/BookingsPanel';

interface ActivitiesPageProps {
    tab: ActivitiesTabs;
}

const Activities: FC<ActivitiesPageProps> = ({ tab }) => {
    const [totals, setTotals] = useState({
        totalAll: 0,
        totalToBook: 0,
        totalRequested: 0,
        totalToCancel: 0,
        totalProcessed: 0,
    });

    const params = useParams();

    return (
        <Grid container>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <NumberedTabs value={tab}>
                        <NumberedTab
                            number={totals.totalAll}
                            label="All"
                            url={`/activities/${params.bucketID}/all`}
                            index={ActivitiesTabs.All}
                            selectedIndex={tab}
                        />
                        <NumberedTab
                            number={totals.totalToBook}
                            label="To book"
                            url={`/activities/${params.bucketID}/to-book`}
                            index={ActivitiesTabs.ToBook}
                            selectedIndex={tab}
                        />
                        <NumberedTab
                            number={totals.totalRequested}
                            label="Requested"
                            url={`/activities/${params.bucketID}/requested`}
                            index={ActivitiesTabs.Requested}
                            selectedIndex={tab}
                        />
                        <NumberedTab
                            number={totals.totalToCancel}
                            label="To cancel"
                            url={`/activities/${params.bucketID}/to-cancel`}
                            index={ActivitiesTabs.ToCancel}
                            selectedIndex={tab}
                        />
                        <NumberedTab
                            number={totals.totalProcessed}
                            label="Processed"
                            index={ActivitiesTabs.Processed}
                            selectedIndex={tab}
                            url={`/activities/${params.bucketID}/processed`}
                        />
                    </NumberedTabs>
                </Grid>
            </Grid>
            <TabPanel value={ActivitiesTabs.All} selectedValue={tab}>
                {tab === ActivitiesTabs.All && (
                    <BookingsPanel
                        activeTab={tab}
                        setTotals={setTotals}
                        heading="All"
                        noRowsMessage="No data"
                        exportEnabled={false}
                        totalsField="total_all"
                        endpoint="requirements/all"
                        tabToEnable={ActivitiesTabs.All}
                    />
                )}
            </TabPanel>
            <TabPanel value={ActivitiesTabs.ToBook} selectedValue={tab}>
                {tab === ActivitiesTabs.ToBook && (
                    <BookingsPanel
                        activeTab={tab}
                        setTotals={setTotals}
                        heading="Requirements to book"
                        noRowsMessage="No requirements to book"
                        exportEnabled={false}
                        totalsField="total_to_book"
                        modalMode="CREATE"
                        endpoint="requirements/to-book"
                        tabToEnable={ActivitiesTabs.ToBook}
                    />
                )}
            </TabPanel>
            <TabPanel value={ActivitiesTabs.Requested} selectedValue={tab}>
                {tab === ActivitiesTabs.Requested && (
                    <BookingsPanel
                        activeTab={tab}
                        setTotals={setTotals}
                        heading="Requested bookings"
                        noRowsMessage="No bookings have been requested"
                        exportEnabled={false}
                        totalsField="total_requested"
                        modalMode="UPDATE"
                        endpoint="requirements/requested"
                        tabToEnable={ActivitiesTabs.Requested}
                    />
                )}
            </TabPanel>
            <TabPanel value={ActivitiesTabs.ToCancel} selectedValue={tab}>
                {tab === ActivitiesTabs.ToCancel && (
                    <BookingsPanel
                        activeTab={tab}
                        setTotals={setTotals}
                        heading="Bookings to cancel"
                        noRowsMessage="No bookings to cancel"
                        exportEnabled={false}
                        totalsField="total_to_cancel"
                        modalMode="UPDATE"
                        endpoint="bookings/to-cancel"
                        tabToEnable={ActivitiesTabs.ToCancel}
                    />
                )}
            </TabPanel>
            <TabPanel value={ActivitiesTabs.Processed} selectedValue={tab}>
                {tab === ActivitiesTabs.Processed && (
                    <BookingsPanel
                        activeTab={tab}
                        setTotals={setTotals}
                        heading="Processed bookings"
                        noRowsMessage="No bookings have been processed"
                        exportEnabled={true}
                        totalsField="total_processed"
                        modalMode="UPDATE"
                        endpoint="requirements/processed"
                        tabToEnable={ActivitiesTabs.Processed}
                    />
                )}
            </TabPanel>
        </Grid>
    );
};

export default Activities;
