import { Modal, PrimaryButton, SecondaryButton, Heading, TextField, Select, SelectOption } from '@get-e/react-components';
import { Box, Checkbox, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridRowId } from '@mui/x-data-grid-pro';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckIcon from '@mui/icons-material/Check';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';

import { Currency } from '../api/types';
import { useNotificationContext } from '../../../context/NotificationContext';
import { createBooking, updateBooking } from '../api/booking';
import getValue from '../../../helpers/getValue';
import { IS_DECIMAL_TWO_DIGITS } from '../../../helpers/validation/regexRules';
import { useCurrencies } from '../api/useCurrency';
import { useCustomFields } from '../api/useCustomFields';
import { CustomField } from '../api/types';
import CopyToClipboardButton from '../../../components/CopyToClipboardButton';
import { useCurrentBucket } from '../../../context/CurrentBucketContext';

const useStyles = makeStyles(() => ({
    modal: { minWidth: '1000px' },
    dataRow: { marginBottom: '16px', alignItems: 'center' },
    label: { width: '40%', color: '#788B9B' },
    gridContainer: { display: 'flex' },
    gridColumn: { display: 'flex', flexDirection: 'column' },
    select: { width: '100%', minWidth: '300px' },
    textField: { width: '100%', minWidth: '300px' },
    copy: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        transition: '150ms all ease-in-out',
        '&:hover': {
            opacity: '0.7',
        },
    },
    copyIcon: { marginLeft: '16px', color: '#788B9B' },
    checkboxErrorMessage: {
        color: '#b72323',
        fontSize: '12px',
    },
}));

interface UpdateModalProps {
    onClose: () => void;
    open: GridRowId | null;
    ticketData?: any;
    mode?: 'CREATE' | 'UPDATE';
    refetch: () => void;
}

const UpdateModal: FC<UpdateModalProps> = ({ mode, onClose, open, ticketData, refetch }) => {
    const classes = useStyles();

    const [ticketNumber, setTicketNumber] = useState(ticketData?.booking?.ticket_number || '');
    const [ticketPrice, setTicketPrice] = useState(ticketData?.booking?.price || '');
    const [currency, setCurrency] = useState(ticketData?.booking?.currency?.id || '');
    const [refundable, setRefundable] = useState(!!ticketData?.booking?.is_refundable);
    const [status, setStatus] = useState(ticketData?.booking?.status || '');
    const [comment, setComment] = useState(ticketData?.booking?.comment || '');
    const [bookingType, setBookingType] = useState(ticketData?.booking?.type || '');
    const [customFieldValues, setCustomFieldValues] = useState<{ [id: string]: any }>({});
    const [ticketPriceError, setTicketPriceError] = useState('');
    const [currencyError, setCurrencyError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [ticketNumberError, setTicketNumberError] = useState('');
    const [bookingTypeError, setBookingTypeError] = useState('');
    const [customFieldErrors, setCustomFieldErrors] = useState<{ [id: string]: string }>({});
    const [dataModified, setDataModified] = useState(false);

    const { data: currencyOptions = [] } = useCurrencies();
    const { data: customFields = [] } = useCustomFields();

    const currentBucket = useCurrentBucket();

    const timeSourceStart = currentBucket?.bucket?.is_local_time
        ? dayjs(ticketData?.start_time_local)
        : dayjs.utc(ticketData?.start_time_zulu);

    const timeSourceEnd = currentBucket?.bucket?.is_local_time
        ? dayjs(ticketData?.end_time_local)
        : dayjs.utc(ticketData?.end_time_zulu);

    const { mutate: updateTicketMutation, isLoading: isUpdatingTickets } = useMutation(updateBooking, {
        onError: (error: Error) => {
            console.log(error);
        },
    });

    const { mutate: createTicketMutation, isLoading: isCreatingTickets } = useMutation(createBooking, {
        onError: (error: Error) => {
            console.log(error);
        },
    });

    useEffect(() => {
        if (ticketData?.booking?.custom_fields) {
            const initialCustomFieldValues = ticketData?.booking?.custom_fields.reduce((acc: { [id: string]: any }, field: any) => {
                if (field.custom_field.type === 'BOOLEAN') {
                    acc[field.custom_field.id] = field.value === '1' ? true : false;
                } else {
                    acc[field.custom_field.id] = field.value || '';
                }
                return acc;
            }, {});
            setCustomFieldValues(initialCustomFieldValues);
        }
    }, [ticketData?.booking?.custom_fields]);

    const handleCustomFieldChange = (id: number, value: any) => {
        setCustomFieldValues(prevValues => ({
            ...prevValues,
            [id]: value,
        }));
    };

    const handleSubmit = (shouldCloseModal: boolean) => {
        // Dirty solution, hopefully temporary
        const isTicketNumberOptional = currentBucket?.bucket?.name === 'SV ID';

        if (currentBucket?.bucket?.type === 'FULL') validateCustomFields();

        if (!currency && currentBucket?.bucket?.type === 'FULL') setCurrencyError('This field is required');

        if (!status) setStatusError('This field is required');

        if (!ticketNumber && status !== 'TO_BOOK' && !isTicketNumberOptional) {
            setTicketNumberError('This field is required');
            return;
        }

        if (ticketData?.type === 'ANY' && currentBucket?.bucket?.type === 'FULL' && !bookingType)
            setBookingTypeError('This field is required');

        if (status === 'BOOKED' && currentBucket?.bucket?.type === 'FULL' && !ticketPrice) {
            setTicketPriceError('This field is required');
            return;
        }

        const payload = {
            ticketData: { ...ticketData },
            status,
            ticketNumber,
            ticketPrice,
            refundable,
            comment,
            requirementId: ticketData?.id,
            currencyId: currency,
            type: ticketData?.type === 'ANY' ? bookingType : ticketData?.type,
            custom_fields: customFields
                .filter((field: CustomField) => customFieldValues[field.id] !== undefined && customFieldValues[field.id] !== '')
                .map((field: CustomField) => ({
                    id: field.id,
                    value: customFieldValues[field.id],
                })),
        };

        if (mode === 'CREATE' && !ticketData?.booking) {
            createTicketMutation(payload, {
                onSuccess: () => {
                    if (shouldCloseModal) {
                        onClose();
                        refetch();
                    } else {
                        setDataModified(true);
                    }
                },
            });
            return;
        }

        updateTicketMutation(payload, {
            onSuccess: () => {
                if (shouldCloseModal) {
                    onClose();
                    refetch();
                } else {
                    setDataModified(true);
                }
            },
        });
    };

    const handleOnClose = () => {
        setStatus('');
        setComment('');
        setTicketNumber('');
        setRefundable(false);
        setBookingType('');
        setCurrency('');
        onClose();

        if (dataModified) {
            refetch();
        }
    };

    if (!ticketData) return null;

    const disableSave = getValue(() => {
        if (ticketData?.status === 'NOT_BOOKED' && status === 'NOT_BOOKED') return true;

        if (status === 'BOOKED') {
            return ticketNumber.length === 0;
        }

        if (ticketData?.status === 'CANCELLED') return true;

        return false;
    });

    const disableInputs = getValue(() => {
        if (ticketData?.status === 'NOT_BOOKED' && status === 'NOT_BOOKED') {
            return true;
        }

        if (ticketData?.status === 'CANCELLED') return true;

        return false;
    });

    const checkDecimalValue = (value: string) => {
        if (IS_DECIMAL_TWO_DIGITS.test(value)) {
            setTicketPrice(value);
            setTicketPriceError('');
        }
    };

    const renderCustomField = (field: CustomField) => {
        const value = customFieldValues[field.id] !== undefined ? customFieldValues[field.id] : '';
        const error = customFieldErrors[field.id] || '';

        if (field.type === 'TEXT') {
            return (
                <TextField
                    className={classes.textField}
                    sx={{ minWidth: '250px' }}
                    required={field.required && currentBucket?.bucket?.type === 'FULL'}
                    label={field.label}
                    value={value}
                    error={!!error}
                    helperText={error || ''}
                    onChange={e => handleCustomFieldChange(field.id, e.target.value)}
                />
            );
        }

        if (field.type === 'BOOLEAN') {
            return (
                <div>
                    <Checkbox
                        required={field.required && currentBucket?.bucket?.type === 'FULL'}
                        onChange={e => {
                            handleCustomFieldChange(field.id, e.target.checked);
                        }}
                        checked={!!value}
                    />
                    {error && <Box className={classes.checkboxErrorMessage}>{error}</Box>}
                </div>
            );
        }

        if (field.type === 'ENUM' && field.options) {
            return (
                <div className={classes.select}>
                    <Select
                        label={field.label}
                        value={value}
                        onChange={newOption => handleCustomFieldChange(field.id, newOption)}
                        error={!!error}
                        required={field.required && currentBucket?.bucket?.type === 'FULL'}
                        helperText={error || ''}
                    >
                        {field.options.map((option: string) => (
                            <SelectOption key={option} value={option}>
                                {option}
                            </SelectOption>
                        ))}
                    </Select>
                </div>
            );
        }

        return null;
    };

    const validateCustomFields = (): boolean => {
        let isValid = true;
        const errors: { [id: string]: string } = {};

        customFields.forEach((field: CustomField) => {
            const value = customFieldValues[field.id];

            if (field.required) {
                if (field.type === 'TEXT' && !value) {
                    errors[field.id] = 'This field is required';
                    isValid = false;
                }

                if (field.type === 'ENUM' && !value) {
                    errors[field.id] = 'This field is required';
                    isValid = false;
                }

                if (field.type === 'BOOLEAN' && value === undefined) {
                    errors[field.id] = 'This field is required';
                    isValid = false;
                }
            }
        });

        setCustomFieldErrors(errors);
        return isValid;
    };

    const getCopyToClipboardText = () => {
        if (!ticketData) return '';

        if (ticketData?.type === 'HOTEL') {
            return [
                `${ticketData?.departure_identifier || 'N/A'}`,
                `Airport: ${ticketData?.arrival_identifier || 'N/A'}`,
                `Check-in: ${timeSourceStart.format('DD MMM YYYY HH:mm')} ${currentBucket?.bucket?.is_local_time ? 'LT' : 'UTC'}`,
                `Check-out: ${timeSourceEnd.format('DD MMM YYYY HH:mm')} ${currentBucket?.bucket?.is_local_time ? 'LT' : 'UTC'}`,
                `Crew code: ${ticketData?.employee.number.toString()}`,
                `First name: ${ticketData?.employee.first_name}`,
                `Last name: ${ticketData?.employee.last_name}`,
            ]
                .filter(Boolean)
                .join('\n');
        } else {
            return [
                `${ticketData?.departure_identifier || 'N/A'} to ${ticketData?.arrival_identifier || 'N/A'}`,
                ` `,
                `Date: ${timeSourceStart.format('DD MMM YYYY')}`,
                `Time: ${timeSourceStart.format('HH:mm')} - ${timeSourceEnd.format('HH:mm')} ${
                    currentBucket?.bucket?.is_local_time ? 'LT' : 'UTC'
                }`,
                `Departure: ${ticketData?.departure_identifier}`,
                `Arrival: ${ticketData?.arrival_identifier}`,
                `Crew code: ${ticketData?.employee.number.toString()}`,
                `First name: ${ticketData?.employee.first_name}`,
                `Last name: ${ticketData?.employee.last_name}`,
            ]
                .filter(Boolean)
                .join('\n');
        }
    };

    return (
        <Modal open={open !== null} onClose={handleOnClose} maxWidth="xl">
            <div className={classes.modal}>
                <div>
                    <Heading level={2}>
                        {ticketData?.type === 'HOTEL'
                            ? `${ticketData?.departure_identifier}`
                            : `${ticketData?.departure_identifier} to ${ticketData?.arrival_identifier}`}{' '}
                        <CopyToClipboardButton text={getCopyToClipboardText()} />
                    </Heading>
                </div>
                <div className={classes.gridContainer}>
                    <div className={classes.gridColumn} style={{ width: '60%' }}>
                        {ticketData?.type === 'HOTEL' ? (
                            <>
                                <DataRow label="Check in">
                                    {timeSourceStart.format('DD MMM YYYY HH:mm')}{' '}
                                    {currentBucket?.bucket?.is_local_time ? ' LT' : ' UTC'}
                                </DataRow>
                                <DataRow label="Check out">
                                    {timeSourceEnd.format('DD MMM YYYY HH:mm')} {currentBucket?.bucket?.is_local_time ? ' LT' : ' UTC'}
                                </DataRow>
                            </>
                        ) : (
                            <>
                                <DataRow label="Date">{timeSourceStart.format('DD MMM YYYY')}</DataRow>
                                <DataRow label="Time">
                                    {
                                        <span className="dataGridTime">
                                            {timeSourceStart.format('HH:mm')} - {timeSourceEnd.format('HH:mm')}
                                            {currentBucket?.bucket?.is_local_time ? ' LT' : ' UTC'}
                                        </span>
                                    }
                                </DataRow>
                            </>
                        )}
                        <DataRow label="Activity type">{ticketData?.type}</DataRow>
                        <DataRow label={ticketData?.type === 'FLIGHT' ? 'Flight number' : 'Provider & number'}>
                            {ticketData?.provider}
                            {ticketData?.type !== 'FLIGHT' && ' '}
                            {ticketData?.code}
                        </DataRow>
                        <DataRow label="Bucket code">{ticketData?.bucket_code}</DataRow>
                        <DataRow label={ticketData?.type === 'HOTEL' ? 'Hotel name' : 'Departure'}>
                            {ticketData?.departure_identifier}
                        </DataRow>
                        <DataRow label={ticketData?.type === 'HOTEL' ? 'Airport' : 'Arrival'}>
                            {ticketData?.arrival_identifier}
                        </DataRow>

                        <DataRow label="Duty designator">{ticketData?.duty_designations.join(', ')}</DataRow>
                        {ticketData?.type === 'ANY' && (
                            <DataRow label="Booking type">
                                <div className={classes.select}>
                                    <Select
                                        helperText={bookingTypeError}
                                        error={!!bookingTypeError}
                                        label="Booking type"
                                        value={bookingType ?? ''}
                                        onChange={newType => {
                                            setBookingType(newType);
                                        }}
                                    >
                                        <SelectOption value="FLIGHT">Flight</SelectOption>
                                        <SelectOption value="TRAIN">Train</SelectOption>
                                        <SelectOption value="TAXI">Taxi</SelectOption>
                                    </Select>
                                </div>
                            </DataRow>
                        )}
                        <DataRow label="Status">
                            <div className={classes.select}>
                                <Select
                                    helperText={statusError}
                                    label="Status"
                                    error={!!statusError}
                                    value={status ?? ''}
                                    onChange={newStatus => {
                                        if (newStatus === 'TO_BOOK' && !ticketData?.booking) setTicketNumber('');
                                        setStatus(newStatus);
                                    }}
                                    required
                                >
                                    <SelectOption value="TO_BOOK">Not created</SelectOption>
                                    <SelectOption value="REQUESTED">Requested</SelectOption>
                                    <SelectOption value="BOOKED">Booked</SelectOption>
                                    <SelectOption
                                        value="CANCELLED"
                                        disabled={!['BOOKED', 'REQUESTED'].includes(ticketData?.booking?.status)}
                                    >
                                        Cancelled
                                    </SelectOption>
                                </Select>
                            </div>
                        </DataRow>
                        {customFields.map((field: CustomField) => {
                            return (
                                <DataRow key={field.id} label={field.label}>
                                    {renderCustomField(field)}
                                </DataRow>
                            );
                        })}
                        <DataRow label="Ticket number">
                            <TextField
                                className={classes.textField}
                                sx={{ minWidth: '250px' }}
                                disabled={disableInputs || status === 'TO_BOOK'}
                                helperText={ticketNumberError}
                                error={!!ticketNumberError}
                                label="e.g. K3RLW4J"
                                value={ticketNumber}
                                onChange={e => setTicketNumber(e.target.value)}
                            />
                        </DataRow>
                        <DataRow label="Ticket price">
                            <TextField
                                className={classes.textField}
                                disabled={disableInputs}
                                label="e.g. 88.44"
                                value={ticketPrice}
                                onChange={e => checkDecimalValue(e.target.value)}
                                helperText={ticketPriceError}
                                error={!!ticketPriceError}
                                required={currentBucket?.bucket?.type === 'FULL'}
                            />
                        </DataRow>
                        <DataRow label="Currency">
                            <div className={classes.select}>
                                <Select
                                    helperText={currencyError}
                                    error={!!currencyError}
                                    label="Currency"
                                    value={currency ?? ''}
                                    onChange={newStatus => {
                                        setCurrency(newStatus);
                                    }}
                                >
                                    {currencyOptions?.map((curr: Currency) => (
                                        <SelectOption key={curr.id} value={curr.id}>
                                            {curr.iso}
                                        </SelectOption>
                                    ))}
                                </Select>
                            </div>
                        </DataRow>
                        <DataRow label="Refundable">
                            <Checkbox disabled={disableInputs} onClick={() => setRefundable(!refundable)} checked={refundable} />
                        </DataRow>
                        <DataRow label="Comments">
                            <TextField
                                className={classes.textField}
                                disabled={disableInputs}
                                label="Add a comment"
                                value={comment}
                                onChange={e => setComment(e.target.value)}
                                minRows={4}
                                multiline
                            />
                        </DataRow>
                    </div>
                    <div className={classes.gridColumn} style={{ width: '40%' }}>
                        <DataRow label="First name">
                            <CopyData message={ticketData?.employee.first_name} />
                        </DataRow>
                        <DataRow label="Last name">
                            <CopyData message={ticketData?.employee.last_name} />
                        </DataRow>
                        <DataRow label="Gender">
                            <CopyData message={ticketData?.employee.gender} />
                        </DataRow>
                        <DataRow label="Date of birth (dd.mm.yyyy)">
                            <CopyData message={format(dayjs.utc(ticketData?.employee.date_of_birth).valueOf(), 'dd.MM.yyyy')} />
                        </DataRow>
                        <DataRow label="Email">
                            <CopyData message={ticketData?.employee.email_address} />
                        </DataRow>
                        <DataRow label="Mobile">
                            <CopyData message={ticketData?.employee.phone_number} />
                        </DataRow>
                        <DataRow label="Crew code">
                            <CopyData message={ticketData?.employee.number.toString()} />
                        </DataRow>
                        <DataRow label="Employment date">
                            <CopyData message={format(dayjs.utc(ticketData?.employee.date_of_employment).valueOf(), 'dd.MM.yyyy')} />
                        </DataRow>
                    </div>
                </div>
                <Grid container spacing={2}>
                    <Grid item>
                        <PrimaryButton
                            disabled={disableSave}
                            onClick={() => {
                                handleSubmit(false);
                            }}
                            loading={isUpdatingTickets || isCreatingTickets}
                        >
                            Save
                        </PrimaryButton>
                    </Grid>
                    <Grid item>
                        <PrimaryButton
                            disabled={disableSave}
                            onClick={() => {
                                handleSubmit(true);
                            }}
                            loading={isUpdatingTickets || isCreatingTickets}
                        >
                            Save & close
                        </PrimaryButton>
                    </Grid>
                    <Grid item>
                        <SecondaryButton disabled={isUpdatingTickets || isCreatingTickets} onClick={handleOnClose}>
                            Close
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

export default UpdateModal;

const DataRow: FC<{ label: string; children: ReactNode }> = ({ label, children }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.dataRow}>
            <Grid item className={classes.label}>
                {label}
            </Grid>
            <Grid>{children}</Grid>
        </Grid>
    );
};

const CopyData: FC<{ message: string }> = ({ message }) => {
    const classes = useStyles();
    const [copied, setCopied] = useState(false);
    const { showNotification } = useNotificationContext();

    if (!message?.length) return null;

    return (
        <span
            className={classes.copy}
            onClick={() => {
                copy(message);
                setCopied(true);
                showNotification('Copied text');
            }}
        >
            {message}
            {copied ? (
                <CheckIcon fontSize="small" className={classes.copyIcon} />
            ) : (
                <FileCopyOutlinedIcon fontSize="small" className={classes.copyIcon} />
            )}
        </span>
    );
};
