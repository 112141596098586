import { Box, TextField } from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import { DateRangePicker, LocalizationProvider, SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { PrimaryButton } from '@get-e/react-components';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

interface CustomToolbarProps {
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
    dateRange?: any;
    setDateRange?: (range: any) => void;
    onExportClick?: any;
    showDateRange?: boolean;
    showExportButton?: boolean;
}

const useStyles = makeStyles({
    gridToolbarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    exportButton: {
        height: '40px',
        marginLeft: '10px',
    },
});

const CustomToolbar = ({
    searchTerm,
    setSearchTerm,
    dateRange,
    setDateRange,
    onExportClick,
    showExportButton,
    showDateRange,
}: CustomToolbarProps): JSX.Element => {
    const classes = useStyles();
    const [tempDateRange, setTempDateRange] = useState(dateRange);

    return (
        <GridToolbarContainer className={classes.gridToolbarContainer}>
            <Box>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
            </Box>
            <Box sx={{ marginRight: '10px' }}>
                <TextField
                    autoFocus={!!searchTerm}
                    size="small"
                    value={searchTerm}
                    label="Search"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                    sx={{ marginRight: '10px' }}
                />
                {showDateRange && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            sx={{ width: '260px' }}
                            value={tempDateRange}
                            label="Date range"
                            onChange={newValue => setTempDateRange(newValue)}
                            onAccept={newValue => setDateRange?.(newValue)}
                            slotProps={{
                                actionBar: {
                                    actions: ['clear'],
                                },
                                textField: {
                                    InputProps: {
                                        endAdornment: <CalendarTodayOutlined style={{ color: '#788B9B', fontSize: '1.2rem' }} />,
                                    },
                                    sx: {
                                        '& .MuiOutlinedInput-input': {
                                            height: '41px !important',
                                            padding: 0,
                                        },
                                        '& .MuiInputLabel-root': {
                                            top: '-6px',
                                        },
                                    },
                                },
                                day: {
                                    sx: {
                                        '& .MuiDateRangePickerDay-dayInsideRangeInterval': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.12)',
                                        },
                                        '& .MuiDateRangePickerDay-rangeIntervalDayPreview': {
                                            borderRadius: '50%',
                                            borderStyle: 'dashed',
                                            borderColor: 'rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12)',
                                            transition: 'none',
                                        },
                                        '& .MuiDateRangePickerDay-dayOutsideRangeInterval': {
                                            ':hover': {
                                                border: '1px solid rgb(158, 158, 158)',
                                            },
                                        },
                                        '& .MuiDateRangePickerDay-dayOutsideRangeInterval:hover': {
                                            border: '1px solid rgb(158, 158, 158)',
                                        },
                                    },
                                },
                            }}
                            slots={{ field: SingleInputDateTimeRangeField }}
                            format="DD MMM YYYY"
                        />
                    </LocalizationProvider>
                )}
                {showExportButton && (
                    <PrimaryButton className={classes.exportButton} onClick={onExportClick}>
                        Export
                    </PrimaryButton>
                )}
            </Box>
        </GridToolbarContainer>
    );
};

export default CustomToolbar;
