import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';

import emptyAirport from '../images/emptyAirport.svg';

const useDataGridStyles = makeStyles(() =>
    createStyles({
        dataGridHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: '16px 10px',
            borderBottom: '1px solid #e0e0e0',
            '& h2': {
                margin: '0px',
            },
        },
        dataGrid: {
            width: '100%',
            minWidth: '100px',
            color: '#073690',
            border: '1px solid #e0e0e0',
            borderRadius: '4px',

            '& .MuiDataGrid-root': {
                border: 'none',
                minHeight: '100px',
            },
        },
        dataGridNoRows: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color: '#788B9B',
            fontWeight: 700,
        },
        iconNoRows: {
            height: 135,
            width: 190,
            marginBottom: '0.5rem',
        },
    }),
);

interface CustomProps {
    noRows?: string;
    enableExport?: boolean;
    noResultImage?: string;
    noRowsClassName?: string;
}

const CustomNoRowsOverlay = (props: CustomProps): JSX.Element => {
    const classes = useDataGridStyles();
    const { noRows, noRowsClassName } = props;

    return (
        <div className={clsx(classes.dataGridNoRows, noRowsClassName ?? '')}>
            <img src={emptyAirport} alt={noRows} className={classes.iconNoRows} />
            {noRows}
        </div>
    );
};

export default CustomNoRowsOverlay;
