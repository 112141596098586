import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const DRAWER_WIDTH = 300;
const CLOSED_DRAWER_WITH = 48;

export { DRAWER_WIDTH, CLOSED_DRAWER_WITH };

const useNavigationBarStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: DRAWER_WIDTH,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            '& [class*="MuiGrid-container"]': {
                height: '100%',
                '& .MuiListItem-root.Mui-selected': { backgroundColor: '#001261' },
                '& .MuiListItem-root:hover': { backgroundColor: '#032378' },
            },
        },
        drawerOpen: {
            width: '100%',
            zIndex: 1200,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.up('sm')]: { width: DRAWER_WIDTH },
            '& [class*="MuiGrid-root"]': { flexWrap: 'nowrap' },
        },
        drawerClose: {
            width: 0,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            [theme.breakpoints.up('sm')]: { width: CLOSED_DRAWER_WITH },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            height: 56,
            minHeight: 56,
            '& [class*="MuiChip-root"]': {
                fontWeight: 'bold',
                color: theme.palette.common.white,
                backgroundColor: '#b712c3',
                flexGrow: 1,
            },
        },
        companyInformationContainer: {
            width: '100%',
            paddingBottom: '2em',
            textAlign: 'center',
        },
    }),
);

export default useNavigationBarStyles;
