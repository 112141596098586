import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDataGridStyles } from '../styles/useDataGridStyles';
import { GridRowId, DataGridPro, GridSortModel, GridInitialState, DataGridProProps, useGridApiRef } from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';
import { Heading } from '@get-e/react-components';
import { useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

import UpdateModal from './UpdateModal';
import CustomToolbar from '../../../components/CustomToolbar';
import CustomNoRowsOverlay from '../../../components/CustomRowsNoOverlay';
import { DataItem } from '../api/types';
import downloadExcel from '../api/downloadExcel';
import { useCurrentBucket } from '../../../context/CurrentBucketContext';
import { parseDateRange } from '../helpers/parseDateRange';
import { fieldSortMapping, getActivitiesColumns, groupingColDef } from '../helpers/columns';
import { useBookings } from '../api/useBookings';
import { ActivitiesTabs } from '../index';

const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        padding: '1rem 0.625rem',
        alignItems: 'center',
        border: '1px solid #e0e0e0',
        borderBottom: 'none',
        '& h2': {
            marginBottom: 0,
        },
    },
    dataGrid: {
        minHeight: '400px',
    },
}));

const initialState: GridInitialState = {
    sorting: {
        sortModel: [{ field: 'date', sort: 'asc' }],
    },
};

interface BookingsPanelProps {
    activeTab: number;
    setTotals: (totals: any) => void;
    heading: string;
    noRowsMessage: string;
    exportEnabled: boolean;
    totalsField: string;
    modalMode?: 'CREATE' | 'UPDATE';
    endpoint: string;
    tabToEnable: ActivitiesTabs;
}

const BookingsPanel = ({
    activeTab,
    setTotals,
    heading,
    noRowsMessage,
    exportEnabled,
    totalsField,
    modalMode,
    endpoint,
    tabToEnable,
}: BookingsPanelProps) => {
    const storedDateRange = parseDateRange(sessionStorage.getItem('dateRange'));

    const [openModal, setOpenModal] = useState<GridRowId | null>(null);
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState(sessionStorage.getItem('searchTerm') || '');
    const [perPage, setPerPage] = useState(20);
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>(storedDateRange);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'date', sort: 'asc' }]);

    const order_by = sortModel.length > 0 && sortModel[0] ? fieldSortMapping[sortModel[0]?.field] : undefined;
    const order_direction = sortModel.length > 0 ? sortModel[0]?.sort?.toUpperCase() : undefined;

    const apiRef = useGridApiRef();
    const dataGridClasses = useDataGridStyles();
    const classes = useStyles();
    const { bucketID } = useParams();
    const currentBucket = useCurrentBucket();
    const { data, isFetching, refetch } = useBookings(
        activeTab,
        searchTerm,
        page,
        perPage,
        bucketID || '',
        dateRange as any,
        order_by as any,
        order_direction as any,
        endpoint,
        tabToEnable,
    );

    const ticketData = useMemo(() => {
        if (data) {
            return data?.data?.find((ticket: DataItem) => ticket.id === openModal);
        }
    }, [openModal, data]);

    const onExportClick = () => {
        downloadExcel(dateRange);
    };

    useEffect(() => {
        if (data) {
            setTotals({
                totalAll: data?.meta?.total_all,
                totalToBook: data?.meta.total_to_book,
                totalRequested: data?.meta.total_requested,
                totalToCancel: data?.meta?.total_to_cancel,
                totalProcessed: data?.meta?.total_processed,
            });
        }
    }, [data]);

    useEffect(() => {
        sessionStorage.setItem('searchTerm', searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        if (dateRange) {
            const validDateRange = dateRange.map(d => (d ? d.toISOString() : null));
            sessionStorage.setItem('dateRange', JSON.stringify(validDateRange));
        }
    }, [dateRange]);

    const getTreeDataPath: DataGridProProps['getTreeDataPath'] = useCallback(
        (row: DataItem) => {
            const isLocalTime = currentBucket?.bucket?.is_local_time;

            const timeSourceStart = isLocalTime ? row.start_time_local : row.start_time_zulu;
            const timeSourceEnd = isLocalTime ? row.end_time_local : row.end_time_zulu;
            const timeLabel = isLocalTime ? 'LT' : 'UTC';

            const formattedStartTime = isLocalTime
                ? dayjs(timeSourceStart).format('HH:mm')
                : dayjs.utc(timeSourceStart).format('HH:mm');
            const formattedEndTime = isLocalTime ? dayjs(timeSourceEnd).format('HH:mm') : dayjs.utc(timeSourceEnd).format('HH:mm');

            const date = isLocalTime ? dayjs(timeSourceStart).format('DD MMM YYYY') : dayjs.utc(timeSourceEnd).format('DD MMM YYYY');
            const route = `${row.departure_identifier}-${row.arrival_identifier}`;
            const groupingKey = `${date} | ${formattedStartTime} - ${formattedEndTime} ${timeLabel} | ${route}`;

            return [groupingKey, row.id.toString()];
        },
        [currentBucket],
    );

    const isGroupingEnabled = useMemo(() => currentBucket?.bucket?.is_grouped, [currentBucket]);

    return (
        <>
            <div className={dataGridClasses.dataGrid}>
                <div className={classes.header}>
                    <Heading level={2}>{heading}</Heading>
                </div>
                <DataGridPro<DataItem>
                    apiRef={apiRef}
                    treeData={isGroupingEnabled}
                    getTreeDataPath={getTreeDataPath}
                    groupingColDef={groupingColDef}
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                showDateRange
                                showExportButton={exportEnabled}
                                onExportClick={onExportClick}
                            />
                        ),
                        noRowsOverlay: () => <CustomNoRowsOverlay noResultImage="" noRowsClassName="" noRows={noRowsMessage} />,
                    }}
                    loading={isFetching}
                    className={classes.dataGrid}
                    rows={data?.data || []}
                    columns={getActivitiesColumns(!!currentBucket?.bucket?.is_local_time)}
                    rowCount={data?.meta && totalsField in data.meta ? data.meta[totalsField as keyof typeof data.meta] : (0 as any)}
                    onRowClick={params => {
                        if (isGroupingEnabled) {
                            const rowNode = apiRef.current.getRowNode(params.id);
                            if (rowNode && (rowNode.type !== 'leaf' || rowNode.depth !== 1)) {
                                return;
                            }
                        }
                        setOpenModal(params.id);
                    }}
                    pagination
                    paginationMode="server"
                    paginationModel={{ page, pageSize: perPage }}
                    pageSizeOptions={[20]}
                    onPaginationModelChange={model => {
                        setPage(model.page);
                        setPerPage(model.pageSize);
                    }}
                    columnVisibilityModel={{
                        route: !isGroupingEnabled,
                        start_time_local: !isGroupingEnabled,
                    }}
                    initialState={initialState}
                    sortModel={sortModel}
                    onSortModelChange={newSortModel => setSortModel(newSortModel)}
                    sortingMode="server"
                />
                {openModal && (
                    <UpdateModal
                        mode={heading === 'All' ? (ticketData?.booking ? 'UPDATE' : 'CREATE') : modalMode}
                        open={openModal}
                        onClose={() => setOpenModal(null)}
                        ticketData={ticketData}
                        refetch={refetch}
                    />
                )}
            </div>
        </>
    );
};

export default BookingsPanel;
